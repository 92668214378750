import { Button, Col, Form, Input, Modal, Row, Switch } from 'antd';
import api from 'api';
import applyCPFMaskOnTyping from 'helpers/cpfMaskOnType';
import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';

const ProfileSettingsModal = ({ visible, onClose }) => {
    const { data, isLoading, isFetching } = useQuery('accountInfo', async () => {
        const response = await api.get('/accounts');
        return response.data;
    }, { enabled: visible });

    const [form] = Form.useForm();

    useEffect(() => {
        if (data) {
            form.setFieldsValue({ ...data, cpf: applyCPFMaskOnTyping(data.cpf) });
        }
    }, [data, form]);

    const mutation = useMutation(async (values) => {
        const response = await api.put('/accounts', values);
        return response.data;
    }, {
        onSuccess: () => {
            onClose();
        },
        onError: (error) => {
            console.error('Failed to update account:', error);
        }
    });

    const handleFinish = (values) => {
        mutation.mutate(values);
    };

    const handleClose = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title="Editar Perfil"
            open={visible}
            onCancel={handleClose}
            loading={isLoading || isFetching}
            footer={[
                <Button danger key="cancel" onClick={handleClose}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" htmlType="submit" loading={mutation.isLoading} onClick={() => form.submit()}>
                    Salvar
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={data}
                onFinish={handleFinish}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Nome"
                            name="name"
                            rules={[{ required: true, message: 'Por favor, insira seu nome!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Idade"
                            name="age"
                            rules={[{ required: true, message: 'Por favor, insira sua idade!' }]}
                        >
                            <Input value={form.getFieldValue('age')} onChange={(e) => form.setFieldValue('age', e.target.value.replace(/\D/g, ''))} inputMode="numeric" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Por favor, insira seu email!' }]}
                        >
                            <Input type="email" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="CPF"
                            name="cpf"
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Cobranças Ativas?"
                            name={['settings', 'should_charge']}
                            valuePropName="checked"
                        >
                            <Switch disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Notif. pagamentos?"
                            name={['settings', 'must_send_payment_notification']}
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Notif. vendas?"
                            name={['settings', 'must_send_sale_notification']}
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ProfileSettingsModal;