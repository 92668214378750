import React, { useContext, useState } from "react";
import { Form, Input, Button, Spin, App } from "antd";
import lightStyles from "./newCompany.module.css";
import darkStyles from "./newCompany.dark.module.css";
import { ThemeContext } from "themes/themeContext";
import applyCNPJMaskOnTyping from "helpers/applyCnpjMaskOnType";
import api from "api";

const NewCompany = () => {
    const { message } = App.useApp();
    const { theme } = useContext(ThemeContext);
    const isDarkMode = theme === "dark";
    const styles = isDarkMode ? darkStyles : lightStyles;
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            const { data } = await api.post("/companies", values);
            sessionStorage.setItem("companyId", data.id);
            window.location.href = "/app";
        } catch (error) {
            console.error(error);
            message.error("Erro ao cadastrar a empresa, tente novamente em breve", 2);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.formWrapper}>
                <h2 className={styles.title}>Agora vamos cadastrar a sua empresa...</h2>
                <p className={styles.subtitle}>
                    Estamos felizes em ajudar você a gerenciar sua empresa com o <b>Avant</b>. Por favor, preencha as informações abaixo para começar.
                </p>
                <Form layout="vertical" className={styles.form} onFinish={handleSubmit}>
                    {/* Registration Code */}
                    <Form.Item rules={[{required: true, message: "O CNPJ é obrigatório."}]} normalize={(value) => applyCNPJMaskOnTyping(value)} label="CNPJ" name="registration_code">
                        <Input placeholder="Digite o CNPJ da sua empresa" />
                    </Form.Item>

                    {/* Name */}
                    <Form.Item rules={[{required: true, message: "A razão social é obrigatória."}]} label="Razão Social" name="name">
                        <Input placeholder="Digite a razão social da empresa" />
                    </Form.Item>
                   
                    {/* Trading Name */}
                    <Form.Item rules={[{required: true, message: "O nome fantasia é obrigatório."}]} label="Nome Fantasia" name="trading_name">
                        <Input placeholder="Digite o nome fantasia da empresa" />
                    </Form.Item>

                    {/* Website */}
                    <Form.Item label="Website" name="website">
                        <Input placeholder="Digite o website" />
                    </Form.Item>

                    {/* Instagram */}
                    <Form.Item label="Instagram" name="instagram">
                        <Input placeholder="Digite o perfil do Instagram" />
                    </Form.Item>

                    {/* Zip Code */}
                    <Form.Item label="CEP" name="zip_code">
                        <Input placeholder="Digite o CEP" />
                    </Form.Item>

                    {/* State */}
                    <Form.Item label="Estado" name="state">
                        <Input placeholder="Digite o estado" />
                    </Form.Item>

                    {/* City */}
                    <Form.Item label="Cidade" name="city">
                        <Input placeholder="Digite a cidade" />
                    </Form.Item>

                    {/* Neighborhood */}
                    <Form.Item label="Bairro" name="neighborhood">
                        <Input placeholder="Digite o bairro" />
                    </Form.Item>

                    {/* Street */}
                    <Form.Item label="Rua" name="street">
                        <Input placeholder="Digite a rua" />
                    </Form.Item>

                    {/* Address Number */}
                    <Form.Item label="Número" name="address_number">
                        <Input placeholder="Digite o número" />
                    </Form.Item>

                    {/* Phone Number */}
                    <Form.Item label="Telefone" name="phone_number">
                        <Input placeholder="Digite o telefone" />
                    </Form.Item>

                    {/* Submit Button */}
                    <Form.Item className={styles.fullWidth}>
                        <Button type="primary" htmlType="submit" className={styles.button} disabled={loading}>
                            {loading ? <Spin /> : "Cadastrar"}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default NewCompany;
