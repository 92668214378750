import { QueryClient, QueryClientProvider } from "react-query";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Button, Result } from "antd";
import Dashboard from ".";
import Customers from "./customers";
import Layout from "./layout";
import PosRoutes from "./point-of-sales";
import Products from "./products";
import Receivables from "./receivables";
import RegistersRoutes from "./registers";
import NewCompany from "pages/new-company";
import { HomeFilled } from "@ant-design/icons";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});
function DashboardRoutes() {
  const hasToken = ![null, "", undefined].includes(
    sessionStorage.getItem("apiToken"),
  );


  if (hasToken) {
    const navigate = useNavigate();
    return <QueryClientProvider client={queryClient}>
      <Layout>
        <Routes>
          <Route index element={<Dashboard hasToken={hasToken} />} />
          <Route path="products" element={<Products />} />
          <Route path="customers" element={<Customers />} />
          <Route path="new-company" element={<NewCompany />} />
          <Route path="cadastros/*" element={<RegistersRoutes />} />
          <Route path="pos/*" element={<PosRoutes />} />
          <Route path="receivables/*" element={<Receivables />} />
          <Route path="*" element={
            <Result
              status="404"
              title="Página não encontrada."
              subTitle="A página que você tentou acessar ainda não existe."
              extra={<Button icon={<HomeFilled/>} onClick={() => navigate("/app")} type="primary">Ir para página inicial</Button>}
            />
          } />
        </Routes>
      </Layout>
    </QueryClientProvider>
  } else {
    return <Navigate to={"/login"} />
  }
}

export default DashboardRoutes;
