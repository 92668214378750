import { DeleteOutlined } from "@ant-design/icons";
import { App, Button, Drawer, Form, Input, Popconfirm, Space } from "antd";
import api from "api";
import applyCPFMaskOnTyping from "helpers/cpfMaskOnType";
import ImageUpload from "helpers/imageUpload";
import applyPhoneMaskOnTyping from "helpers/phoneMaskOnType";
import { useEffect } from "react";
import { useMutation } from "react-query";

const EditModal = ({ customer, visible, onClose }) => {
    const { message } = App.useApp();
    const [form] = Form.useForm();
    const mode = customer ? "edit" : "create";


    const updateCustomer = async () => {
        const companyId = sessionStorage.getItem("companyId");
        const editedCustomer = form.getFieldsValue(true);
        const payload = {
            ...editedCustomer,
            image_path: editedCustomer.image_path?.split(".com/")[1] ?? null,
            image: editedCustomer.image?.split(',')[1],
            company_id: companyId
        }

        await api.post('/customers', payload)
    }

    const deleteCustomer = async () => {
        const companyId = sessionStorage.getItem("companyId");
        await api.delete(`/customers/${customer?.id}?company_id=${companyId}`);
        onClose();
    }

    const updateCustomerMutation = useMutation({
        mutationFn: () => updateCustomer(),
        onSuccess: () => {
            message.success("Cliente editado com sucesso");
            form.resetFields();
            onClose(true);
        },
        onError: (error) => {
            message.error("Erro ao editar cliente. Tente novamente mais tarde.");
            console.log(error)
        }
    })
    const deleteCustomerMutation = useMutation({
        mutationFn: () => deleteCustomer(),
        onSuccess: () => {
            message.success("Cliente excluído com sucesso");
            form.resetFields();
            onClose(true);
        },
        onError: (error) => {
            message.error("Erro ao excluir cliente. Tente novamente mais tarde.");
            console.log(error)
        }
    })


    const parseValues = (customer) => {
        form.setFieldsValue({
            ...customer,
            image: null,
        });

    }

    useEffect(() => {
        visible && mode === "edit" && parseValues(customer);
    }, [visible])
    return (
        <Drawer
            title={mode === "edit" ? "Editar cliente" : "Novo cliente"}
            open={visible}
            width={'40%'}
            onOk={() => updateCustomerMutation.mutate()}
            loading={updateCustomerMutation.isLoading || deleteCustomerMutation.isLoading}
            onClose={() => { form.resetFields(); onClose(); }}
            extra={
                <Space>
                    {mode === "edit" && <Popconfirm
                        title="Tem certeza que deseja excluir este cliente?"
                        onConfirm={deleteCustomerMutation.mutate}
                        okText="Sim"
                        cancelText="Não"
                        description="Esta ação não poderá ser desfeita"
                        okButtonProps={{ danger: true }}
                    >
                        <Button className="btn-remove" icon={<DeleteOutlined />}>Excluir</Button>
                    </Popconfirm>}
                    <Button type="primary" onClick={() => updateCustomerMutation.mutate()}>Salvar</Button>
                </Space>
            }
        >
            <Form
                className="edit-form"
                form={form}
                style={{ padding: 20, overflowY: "auto", }}
                layout="vertical"
                wrapperCol={{ span: 24 }}
                colon={false}
                size="large"
            >
                <ImageUpload
                    className="image-upload"
                    form={form}
                    field="image_path"
                    onSelect={(image) => form.setFieldValue("image", image)}
                />

                <div className="row">
                    <Form.Item label="Nome completo" name="name">
                        <Input placeholder="Nome do cliente" />
                    </Form.Item>
                </div>
                <div className="row">
                    <Form.Item normalize={(v) => applyCPFMaskOnTyping(v)} label={"CPF"} name="document" >
                        <Input
                            style={{ width: "100%" }}
                            controls={false}
                            placeholder="CPF"
                        />
                    </Form.Item>
                    <Form.Item normalize={(v) => applyPhoneMaskOnTyping(v)} label={"Telefone"} name="phone" >
                        <Input
                            style={{ width: "100%" }}
                            placeholder="Telefone"
                        />
                    </Form.Item>
                </div>
                <div className="row">
                    <Form.Item label="Logradouro" name="street">
                        <Input placeholder="Logradouro" />
                    </Form.Item>
                    <Form.Item label="Bairro" name="neighborhood">
                        <Input placeholder="Bairro" />
                    </Form.Item>
                </div>
                <div className="row">
                    <Form.Item normalize={(v) => `${v}`} label="Número" name="number">
                        <Input style={{ width: "100%" }} placeholder="Número" />
                    </Form.Item>
                    <Form.Item label="Referência" name="address_reference">
                        <Input placeholder="Referência" />
                    </Form.Item>
                </div>
            </Form>
        </Drawer >
    );
}
export default EditModal;