import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from ".";
import Layout from "./layout";
import Portfolios from "./portfolios";
import "./styles.css";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});
function BillingRoutes() {
  const hasToken = ![null, "", undefined].includes(
    sessionStorage.getItem("apiToken"),
  );
  return hasToken ? (
    <QueryClientProvider client={queryClient}>
      <Layout>
        <Routes>
          <Route index element={<Dashboard hasToken={hasToken} />} />
          <Route path="portfolios" element={<Portfolios />} />
        </Routes>
      </Layout>
    </QueryClientProvider>
  ) : (
    <Navigate to={"/login"} />
  );
}

export default BillingRoutes;
