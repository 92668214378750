import { App, Button, Form, Input, InputNumber, Result, Select, Skeleton } from "antd";
import api from "api";
import { parseQuantity } from "helpers/decimalHelper";
import ImageUpload from "helpers/imageUpload";
import toCurrencyInput from "helpers/toCurrencyInput";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

const NewProduct = () => {
    const { message } = App.useApp();
    const [form] = Form.useForm();
    const requiredText = "Campo obrigatório";
    const [mode, setMode] = useState("edit");
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.code === "Space" && mode === "result") {
                form.resetFields(); setMode("edit")
            }
        };
        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, []);

    const getCategories = async () => {
        const companyId = sessionStorage.getItem("companyId");
        const { data: response } = await api.get(`/categories?company_id=${companyId}`);
        return response.data;

    }
    const { data: categories } = useQuery("categories", getCategories, { initialData: [] });

    const createProduct = async (value) => {
        const image = form.getFieldValue("image")?.split(',')[1];
        const unitOfMeasurement = form.getFieldValue("unit_of_measurement") || "unit";
        const payload = {
            ...value,
            image: image,
            company_id: sessionStorage.getItem("companyId"),
            sale_price: `${value.sale_price}`.replace(/\D/g, '') / 100,
            purchase_price: `${value.purchase_price}`.replace(/\D/g, '') / 100,
            quantity_limit: reverseQuantity(value.quantity_limit, unitOfMeasurement),
            quantity: reverseQuantity(value.quantity, unitOfMeasurement),
            unit_of_measurement: unitOfMeasurement
        }
        await api.post('/products', payload)
    }
    const createProductMutation = useMutation({
        mutationFn: (value) => createProduct(value),
        onSuccess: () => {
            message.success("Produto cadastrado com sucesso");
            setMode("result")
        },
        onError: (error) => {
            message.error("Erro ao cadastrar produto. Tente novamente mais tarde.", 2);
            console.log(error)
        }
    })
    if (createProductMutation.isLoading) return <Skeleton style={{ padding: "8vw" }} active paragraph={{ rows: 4 }} />
    if (mode === "result") {
        return <Result
            style={{ padding: "8vw" }}
            status="success"
            title="Produto cadastrado com sucesso"
            subTitle="O produto foi cadastrado com sucesso. Você pode cadastrar outro produto ou voltar para a lista de produtos."
            extra={[
                <Button type="primary" key="list" onClick={() => history.back()}>
                    Voltar para a lista
                </Button>,
                <Button key="add" onClick={() => { form.resetFields(); setMode("edit") }}>
                    Cadastrar outro produto (tecle espaço)
                </Button>,
            ]}
        />
    }
    return (
        <div style={{ paddingInline: "5vw" }}>
            <div className="header">
                <h1>Cadastrar produto</h1>
            </div>
            <Form
                defaultValue={{ unit_of_measurement: "unit" }}
                onFinish={(values) => createProductMutation.mutate(values)}
                className="edit-form"
                form={form}
                layout="vertical"
                wrapperCol={{ span: 24 }}
                colon={false}
                size="large"

            >
                <ImageUpload
                    className="image-upload"
                    form={form}
                    field="image_path"
                    onSelect={(image) => form.setFieldValue("image", image)}
                />

                <div className="row">
                    <Form.Item
                        style={{ width: "60%" }}
                        hasFeedback
                        validateFirst
                        label="Nome"
                        name="name"
                        //required
                        rules={[
                            { required: true, message: requiredText },
                            { min: 3, message: "O nome deve ter no mínimo 3 caracteres" }
                        ]}
                    >
                        <Input placeholder="Nome do produto" />
                    </Form.Item>
                    <Form.Item style={{ width: "35%" }} label={"Categoria"} name="category_id">
                        <Select
                            showSearch
                            placeholder="Selecione as categorias"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
                        >
                            {categories?.map((category) => (
                                <Select.Option key={category.id} value={category.id}>
                                    {category.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className="row">
                    <Form.Item label={"Preço de compra"} name="purchase_price">
                        <InputNumber
                            prefix="R$"
                            style={{ width: "100%" }}
                            controls={false}
                            formatter={(value, { input }) => toCurrencyInput(input, value)}
                            placeholder="Preço de compra"
                        />
                    </Form.Item>
                    <Form.Item label={"Preço de venda"} name="sale_price" >
                        <InputNumber
                            prefix="R$"
                            style={{ width: "100%" }}
                            controls={false}
                            formatter={(value, { input }) => toCurrencyInput(input, value)}
                            placeholder="Preço de venda"
                        />
                    </Form.Item>
                    <Form.Item label="Marca" name="brand">
                        <Input placeholder="Marca" />
                    </Form.Item>
                </div>
                <div className="row">
                    <Form.Item label={"Unidade de medida"} name="unit_of_measurement">
                        <Select defaultValue={"unit"} placeholder="Unidade de medida">
                            <Select.Option value="unit">Unidades</Select.Option>
                            <Select.Option value="kilo">Quilogramas</Select.Option>
                            <Select.Option value="centimeter">Metros</Select.Option>
                            <Select.Option value="liters">Litros</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Estoque atual" name="quantity">
                        <InputNumber controls={false} formatter={(value, { input }) => parseQuantity(value, input, form)} style={{ width: "100%" }} placeholder="Estoque" />
                    </Form.Item>
                    <Form.Item label="Estoque mínimo" name="quantity_limit">
                        <InputNumber controls={false} formatter={(value, { input }) => parseQuantity(value, input, form)} style={{ width: "100%" }} placeholder="Estoque mínimo" />
                    </Form.Item>
                </div>
                <div className="row">
                    <Form.Item label="Fornecedor" name="provider">
                        <Input placeholder="Fornecedor" />
                    </Form.Item>
                    <Form.Item label="Código de barras" name="bar_code">
                        <Input placeholder="Código de barras" />
                    </Form.Item>
                    {/* <Barcode displayValue={false} height={50} background="rgba(0,0,0,0)" lineColor="#1a8a66" value={product.bar_code} /> */}
                </div>
                <div className="row fixed">
                    <Form.Item className="row end">
                        <Button size="large" className="btn-remove" type="default" onClick={() => history.back()}>
                            Cancelar
                        </Button>
                        <Button size="large" type="primary" htmlType="submit">
                            Cadastrar
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default NewProduct;

const reverseQuantity = (quantity, unit) => {
    const sanitizedQuantity = parseInt(`${quantity}`.replace(/\D/g, ''));
    if (["liters", "kilo", "gram"].includes(unit)) return (sanitizedQuantity / 1000).toFixed(3);
    if (unit === "centimeter") return sanitizedQuantity / 100;
    return sanitizedQuantity;
}