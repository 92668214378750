import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Image, Upload } from 'antd';

const ImageUpload = ({ form, field, className, onSelect }) => {
    const ComponentToShow = () => {
        const imagePath = Form.useWatch(field, { form, preserve: true });
        const imageBase64 = Form.useWatch('image', { form, preserve: true })
        if (imagePath || imageBase64) {
            return <div className={className}>
                <Image
                    height={"20vh"}
                    width={"100%"}
                    style={{ objectFit: 'cover', borderRadius: 8 }}
                    src={imageBase64 || "https://" + imagePath}
                >

                </Image>
                <Button
                    style={{ marginTop: 10 }}
                    className='delete-image'
                    type='primary'
                    size='middle'
                    danger
                    // 
                    shape='round'
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        form.setFieldsValue({ [field]: null });
                        form.setFieldsValue({ image: null });
                    }}
                >Excluir imagem</Button>
            </div>
        } else {
            return <Upload
                accept="image/jpeg,image/png"
                listType="picture-card"
                beforeUpload={() => false}
                showUploadList={false}
                onChange={(info) => {
                    getBase64(info.file).then((result) => onSelect(result));
                }}
            >
                <div>
                    Adicionar Imagem
                </div>
            </Upload>
        }
    }
    return <div className={className}>
        <ComponentToShow />
    </div>
}
export default ImageUpload;

const getBase64 = (file) => {
    return new Promise(resolve => {
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};