import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Assignment } from "@mui/icons-material";
import { blue, green, yellow } from "@mui/material/colors";
import { Avatar, Button, Card, List, Result, Statistic, Tag, Typography } from "antd";
import api from "api";
import toBRL from "helpers/toBRL";
import { DateTime } from "luxon";
import { useState } from "react";
import { useQuery } from "react-query";
import Details from "./components/details";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

const Receivables = () => {
    const [page, setPage] = useState(1);
    const [detailProps, setDetailProps] = useState({ open: false, receivable: {} });
    const companyId = sessionStorage.getItem("companyId");
    const navigate = useNavigate();
    const getReceivables = async (currentPage) => {
        try {
            const response = await api.get(
                `/billings?company_id=${companyId}&page=${currentPage}&direction=desc`,
            );
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 404) {
                return { data: [], total_entries: 0, notFound: true };
            }
            throw error;
        }
    };

    const {
        data: receivablesData,
        isLoading,
        isError,
        isFetching,
        refetch,
    } = useQuery(["receivables", page], () => getReceivables(page), {
        initialData: { data: [], total_entries: 0 },
    });

    const handleOpenDetails = (receivable) => setDetailProps({ open: true, receivable: receivable });
    const handleCloseDetails = (shouldRefetch) => {
        if (shouldRefetch) refetch();
        setDetailProps({ open: false, receivable: {} })
    };

    return (
        <div style={{ paddingInline: 20 }}>
            <div className="header">
                <h1>Contas a receber</h1>
            </div>
            <div style={{ paddingBottom: 16 }} className="row">
                <Card className="bshadow" style={{ width: '30%' }} bordered>
                    <Statistic
                        title="Valor das notas em aberto"
                        value={147625.60}
                        precision={2}
                        formatter={toBRL}
                        valueStyle={{
                            color: blue[400],
                        }}
                    />
                </Card>
                <Card className="bshadow" style={{ width: '30%' }} bordered>
                    <Statistic
                        title="Valor já recebido"
                        value={62742.30}
                        precision={2}
                        valueStyle={{
                            color: green[400],
                        }}
                    />
                </Card>
                <Card className="bshadow" style={{ width: '30%' }} bordered>
                    <Statistic
                        title="Valor pendente"
                        value={147625.60 - 62742.30}
                        precision={2}
                        valueStyle={{
                            color: yellow[800],
                        }}
                    />
                </Card>
            </div>
            {isError ? (
                <div>Erro ao buscar contas a receber.</div>
            ) : receivablesData.notFound ? (
                <Result
                    status="404"
                    title="Não encontrado"
                    subTitle="Desculpe, Não encontramos nenhuma conta a receber."
                    extra={<Button onClick={() => navigate("/app/pos")} type="primary">Nova venda</Button>}
                />
            ) : (
                <List
                    size="small"
                    pagination={{
                        position: "bottom",
                        align: "center",
                        showTotal: (total) => `Total: ${total} registros`,
                        total: receivablesData.total_entries,
                        pageSize: receivablesData.page_size,
                        current: page,
                        onChange: (newPage) => setPage(newPage),
                        responsive: true
                    }}
                    scroll={{
                        y: 400,
                    }}
                    style={{ fontSize: 12 }}
                    dataSource={receivablesData.data}
                    bordered
                    renderItem={(item) => (
                        <List.Item actions={
                            [<Button
                                key="details"
                                type="primary"
                                icon={<Assignment />}
                                onClick={() => handleOpenDetails(item)}
                            >
                                Detalhes
                            </Button>]

                        } >
                            <List.Item.Meta
                                avatar={<Avatar size={60} src={item?.image_path && "https://" + item?.image_path} icon={<UserOutlined />} />}
                                title={
                                    <div className={styles.cardHeader}>
                                        <b style={{ marginRight: 20 }}>
                                            <Typography.Text ellipsis>{item.name}</Typography.Text>
                                        </b>
                                        <Tag bordered={false} icon={<InfoCircleOutlined />} color="geekblue-inverse">
                                            última venda {DateTime.fromISO(item.sales[0].updated_at).toRelative({ base: DateTime.local() })}
                                        </Tag>
                                    </div>
                                }
                                description={<div className="row" style={{ width: "50%" }}>
                                    <Statistic
                                        title="Contas pendentes"
                                        value={item.sales.filter(sale => sale.receivables.filter(rec => rec.status === 'pending').length > 0).length}
                                        valueStyle={{
                                            color: yellow[800],
                                        }}
                                    />
                                    <Statistic
                                        title="Valor total"
                                        value={item.sales.reduce((soma, purchase) => {
                                            return soma + parseFloat(purchase.total_amount);
                                        }, 0)}
                                        precision={2}
                                        formatter={toBRL}
                                        valueStyle={{
                                            color: blue[400],
                                        }}
                                    />
                                    <Statistic
                                        title="Valor pendente"
                                        value={item.sales.reduce((soma, purchase) => {
                                            return soma + parseFloat(purchase.receivables.filter(rec => rec.status === 'pending').reduce((soma, rec) => {
                                                return soma + parseFloat(rec.amount);
                                            }, 0));
                                        }, 0)}
                                        precision={2}
                                        formatter={toBRL}
                                        valueStyle={{
                                            color: yellow[800],
                                        }}
                                    />
                                </div>}
                            />
                        </List.Item>
                    )}
                    loading={isLoading || isFetching}
                />
            )}
            <Details {...detailProps} onClose={handleCloseDetails} />
        </div>
    )
}

export default Receivables;