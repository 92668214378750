import NotFound from 'pages/notFound';
import { Route, Routes } from 'react-router-dom';
import NewProduct from './product';
const RegistersRoutes = () => {
    return (
        <Routes>
            <Route path="product" element={<NewProduct />} />
            {/* <Route path="customer" element={<Customers />} /> */}
            <Route path="*" element={<NotFound/>} />
        </Routes>
    )
}
export default RegistersRoutes;