import axios from "axios";

const api = axios.create({
  baseURL: "https://avant-erp.herokuapp.com",
  headers: {
    "Content-Type": "application/json",
    Authorization: sessionStorage.getItem("apiToken"),
  },
});

export default api;
