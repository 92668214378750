import { CardContent, Typography } from "@mui/material";
import { Card, Result } from "antd";
import api from "api";
import AreaChart from "components/charts/areaChart";
import { useQuery } from "react-query";
import React from "react";

const Dashboard = () => {
  const company_id = sessionStorage.getItem("companyId");

  // Api queries handlers
  const getSaleSummary = async (period) => {
    try {
      const { data } = await api.get(
        `/sales/summary?company_id=${company_id}&date=${period}`,
      );
      const parsedData = data.map((i) => {
        return { ...i, value: parseFloat(i.value) };
      });
      return parsedData;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return { error: 404 };
      }
      throw error;
    }
  };

  // Queries to API
  const { data: dailySummary } = useQuery(
    "dailySummary",
    () => getSaleSummary("day"),
    {
      enabled: !!company_id,
      retry: 2,
      initialData: [],
    },
  );

  const { data: monthlySummary } = useQuery(
    "monthlySummary",
    () => getSaleSummary("month"),
    {
      enabled: !!company_id,
      retry: 2,
      initialData: [],
    },
  );

  const SummaryCharts = () => {
    if (dailySummary.error === 404 || monthlySummary.error === 404) {
      return <Result status="404" title="Nenhuma venda encontrada" subTitle="As estatísticas das suas futuras vendas aparecerão aqui." />;
    }

    return (
      <div
        style={{
          margin: 40,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        {[dailySummary, monthlySummary].map((i, ind) => (
          <Card size="small" title={<Typography sx={{ fontSize: 14 }} color="primary" gutterBottom>
            Vendas por {ind === 0 ? "dia" : "mês"}
          </Typography>} style={{ width: "48%" }}>
            <CardContent key={ind}>
              <AreaChart
                money
                data={i}
                labelKey={"period"}
                width="100%"
                height={200}
              />
            </CardContent>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          marginInline: 40,
          marginTop: "10vh",
        }}
      >
        {items.map((i) => (
          <Card size="small"
            title={<Typography sx={{ fontSize: 14 }} color="primary" gutterBottom>
              {i.title}
            </Typography>
            }
            style={{ width: "20%" }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 20, textAlign: "center" }}
                color="text.primary"
                gutterBottom
              >
                {i.value || "R$ 0,00"}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
      <SummaryCharts />
    </div>
  );
};
export default Dashboard;

const items = [
  { title: "Ticket médio", value: null },
  { title: "Vendidos hoje", value: null },
  { title: "Vendidos este mês", value: null },
  { title: "Vendidos este ano", value: null },
];
