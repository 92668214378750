import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "pages/landing/Landing";
import Login from "pages/login/Login";
import DashboardRoutes from "pages/dashboard/dashboardRoutes";
import BillingRoutes from "pages/billing/billingRoutes";
import NotFound from "pages/notFound";
import { ThemeProvider } from "themes/themeContext";
import { App as AntdApp } from "antd";
import { Settings } from "luxon";
import Signup from "pages/signup";
function App() {
  Settings.defaultLocale = "pt-BR";

  return (
    <ThemeProvider>
      <AntdApp>
        <BrowserRouter>
          <Routes>
            <Route index element={<Landing />}></Route>
            <Route path="/signup" element={<Signup />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/billing/*" element={<BillingRoutes />}></Route>
            <Route path="/app/*" element={<DashboardRoutes />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </BrowserRouter>
      </AntdApp>
    </ThemeProvider>
  );
}

export default App;
