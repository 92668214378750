export default function applyCNPJMaskOnTyping(input, fallback) {
    const baseCnpj = input || fallback;
    if (!baseCnpj) return;
    if (baseCnpj.length > 18) return baseCnpj.substring(0, 18);
    let cnpj = baseCnpj.replace(/\D/g, '');

    cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2');
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');
    cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2');

    return cnpj;
}