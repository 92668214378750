import "./Landing.css";
import { useNavigate } from "react-router-dom";
import logo from "assets/images/avant-icon-dark.png";
import phonemock from "assets/images/phone_mock.png";
import divider from "assets/images/divider.png";
import Button from "components/Button";
import Icon from "@mdi/react";
import { mdiLaptop } from "@mdi/js";

const Landing = () => {
  const navigate = useNavigate();
  if (window.innerWidth > 500) {
    return (
      <>
        <div className="container">
          <div className="leftContent">
            <img className="logo" width={200} src={logo} alt="Logo" />
            <img className="phonemock" width={250} src={phonemock} alt="Logo" />
          </div>
          <div className="rightContent">
            <img
              className="divider"
              draggable="false"
              width={250}
              src={divider}
              alt="Logo"
            />
            <div className="nav">
              <div
                onClick={() => navigate("/login")}
                className="platform-button"
              >
                <Icon className="laptop-icon" path={mdiLaptop} size={1} />
                <div className="platform-button-label">
                  Acessar a plataforma
                </div>
              </div>
            </div>
            <div className="field">
              <h1 className="lightText copy">
                Tenha tudo que a sua empresa precisa na palma das suas mãos.
              </h1>
              <Button
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.guilhermex.avant",
                    "_blank",
                    "noopener,noreferrer",
                  )
                }
                type="primary"
              >
                Testar agora
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="leftContentMobile">
        <img className="logo" width={200} src={logo} alt="Logo" />
        <img className="phonemock" width={250} src={phonemock} alt="Logo" />
        <div className="fieldMobile">
          <h1 className="lightText copyMobile">
            Tenha tudo que a sua empresa precisa na palma das suas mãos.
          </h1>
          <Button
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.guilhermex.avant",
                "_blank",
                "noopener,noreferrer",
              )
            }
            type="primary"
          >
            Testar agora
          </Button>
        </div>
      </div>
    );
  }
};
export default Landing;
