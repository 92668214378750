import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import toBRL from "helpers/toBRL";
import MainTheme from "themes/mainTheme";

const AreaChart = (props) => {
  return (
    <ResponsiveContainer
      width={props.width || "50%"}
      height={props.height || 300}
    >
      <BarChart
        style={{ borderRadius: 10 }}
        width={500}
        height={400}
        data={props.data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="1" y1="1" x2="1" y2="0">
            <stop offset="20%" stopColor="#27d8a1" stopOpacity={0.2} />
            <stop offset="80%" stopColor="#27d8a1" stopOpacity={0.8} />
          </linearGradient>
        </defs>
        <XAxis dataKey={props.labelKey} />
        <YAxis
          fontSize={10}
          tickFormatter={(v) => (props.money ? toBRL(v, true) : v)}
        />
        <Tooltip
          cursor={{
            fill: MainTheme.palette.tertiary.dark,
            fillOpacity: 0.1,
            radius: 10,
          }}
          contentStyle={{
            backgroundColor: MainTheme.palette.tertiary.dark,
            borderRadius: 10,
          }}
          formatter={(value) => [
            props.money ? toBRL(value) : value,
            props.tooltipLabel || "Valor",
          ]}
        />
        <Bar radius={5} dataKey="value" fill="url(#colorUv)" />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default AreaChart;
