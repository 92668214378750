import { Paper, TextField, Backdrop, CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { useState } from "react";
import { App, Button } from "antd";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import styles from "./signup.module.css";
import Icon from "assets/images/avant-icon.png";
import IconDark from "assets/images/avant-icon-dark.png";
import { signup } from "helpers/auth";
import applyCPFMaskOnTyping from "helpers/cpfMaskOnType";

const Signup = () => {
    const { message } = App.useApp();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [age, setAge] = useState("");
    const [cpf, setCpf] = useState("");
    const [fullName, setFullName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const isDarkMode = () => localStorage.getItem("theme") === "dark";

    const handleSignup = () => {
        if (password !== confirmPassword) {
            message.error("As senhas não coincidem", 2);
            return;
        }
        signup(
            email,
            password,
            confirmPassword,
            fullName,
            cpf,
            age,
            (e) => message.error(e || "Preencha todos os campos corretamente", 2),
            (loading) => setIsLoading(loading),
        );
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const handleGoBack = () => {
        navigate("/login");
    };

    return (
        <div className={styles.backdrop}>
            <Paper className={styles["container-signup"]} elevation={8}>
                <img
                    alt="ícone avant"
                    className={styles.icon}
                    src={isDarkMode() ? IconDark : Icon}
                />
                <div className={styles["header-text"]}>
                    Bem-vindo ao Avant ERP
                </div>
                <div className={styles["description-text"]}>
                    Transforme a gestão da sua empresa com o Avant ERP. Cadastre-se agora e comece a revolucionar seu negócio!
                </div>
                <div className={styles["signup-form"]}>
                    <TextField
                        onKeyDown={(e) => e.key === "Enter" && handleSignup()}
                        onChange={(e) => setEmail(e.target.value)}
                        className={styles.input}
                        label="E-mail"
                        variant="outlined"
                    />
                    <TextField
                        onKeyDown={(e) => e.key === "Enter" && handleSignup()}
                        onChange={(e) => setFullName(e.target.value)}
                        className={styles.input}
                        label="Nome Completo"
                        variant="outlined"
                    />
                    <TextField
                        onKeyDown={(e) => e.key === "Enter" && handleSignup()}
                        onChange={(e) => setAge(e.target.value.replace(/\D/g, ""))}
                        className={styles.input}
                        label="Idade"
                        variant="outlined"
                        value={age}
                    />
                    <TextField
                        onKeyDown={(e) => e.key === "Enter" && handleSignup()}
                        onChange={(e) => setCpf(applyCPFMaskOnTyping(e.target.value.replace(/\D/g, "")))}
                        className={styles.input}
                        value={cpf || ""}
                        label="CPF"
                        variant="outlined"
                    />
                    <TextField
                        onKeyDown={(e) => e.key === "Enter" && handleSignup()}
                        onChange={(e) => setPassword(e.target.value)}
                        type={showPassword ? "text" : "password"}
                        className={styles.input}
                        label="Senha"
                        name="new-password"
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        onKeyDown={(e) => e.key === "Enter" && handleSignup()}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type={showConfirmPassword ? "text" : "password"}
                        className={styles.input}
                        label="Confirmar Senha"
                        name="confirm-password"
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle confirm password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <Button
                    type="primary"
                    onClick={handleSignup}
                    className={styles["btn-submit"]}
                    variant="contained"
                >
                    <b>Cadastrar</b>
                </Button>
                <Button
                    type="text"
                    onClick={handleGoBack}
                    className={styles["btn-back"]}
                >
                    Voltar
                </Button>
            </Paper>
            <Backdrop
                sx={{
                    backgroundColor: "rgba(0,0,0,0.85)",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
            >
                <CircularProgress color="primary" />
            </Backdrop>
        </div>
    );
};

export default Signup;