import "intl";
//import 'intl/locale-data/jsonp/pt-BR'; // or any other locale you need

export default function toBRL(v1, compact = false) {
  if (!v1 && v1 !== 0) {
    return;
  }

  // using the NumberFormat to format the parameter in brazilian currency
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: compact ? "decimal" : "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    notation: compact ? "compact" : "standard",
  });

  return formatter.format(parseFloat(v1)).replace(/^(\D+)/, "$1 ");
}
