import { EditOutlined } from "@ant-design/icons";
import { AccountBalanceWallet, GridViewOutlined, ViewListOutlined } from "@mui/icons-material";
import { App, Avatar, Button, Card, Input, List, Result } from "antd";
import api from "api";
import NoImage from "assets/images/no-image.png";
import { useState } from "react";
import { useQuery } from "react-query";
import Details from "../receivables/components/details";
import EditModal from "./editModal";

const Customers = () => {
  const { message, modal } = App.useApp();

  const [page, setPage] = useState(1);
  const [mode, setMode] = useState("grid");
  const [query, setQuery] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [receivableOpt, setReceivableOpts] = useState({});
  const [notFound, setNotFound] = useState(false);

  const companyId = sessionStorage.getItem("companyId");
  const getCustomers = async (currentPage, query) => {
    let url = `/customers?company_id=${companyId}&page=${currentPage}`;
    if (query) {
      url += `&name=${query}`;
    }
    try {
      const response = await api.get(url);
      setNotFound(false);
      return response.data;
    } catch (error) {
      if (error.response?.status === 404) {
        setNotFound(true);
        return { data: [], total_entries: 0 };
      } else {
        throw error;
      }
    }
  };

  const getReceivable = async (customer) => {
    setReceivableOpts({ ...receivableOpt, loading: true, open: true });
    try {
      const { data: { data: [receivable] } } = await api.get(`/billings?customer_id=${customer?.id}&company_id=${companyId}`);
      setReceivableOpts((state) => ({ ...state, open: true, receivable: receivable, customer: customer }));
    } catch (error) {
      console.log(error)
      setReceivableOpts((state) => ({ ...state, loading: false, open: false, receivable: null, customer: null }));
      if (error.response?.status === 404) modal.warning({ title: "Nenhuma nota encontrada.", content: "Não há notas para este cliente." });
      else message.error("Erro ao buscar notas.");
    } finally {
      setReceivableOpts((state) => ({ ...state, loading: false }));
    }
  };

  const handleCloseReceivables = () => setReceivableOpts((state) => ({ ...state, open: false, receivable: null, customer: null }));

  const {
    data: customersData,
    isLoading,
    isError,
    isFetching,
    refetch
  } = useQuery(["customers", page, query], () => getCustomers(page, query), {
    initialData: { data: [], total_entries: 0 },
  });

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const handleEditCustomer = (customer, shouldRefetch) => {
    shouldRefetch && refetch();

    if (customer) {
      setSelectedCustomer(customer);
      setIsEditModalVisible(true);
    } else {
      setSelectedCustomer(null);
      setIsEditModalVisible(true);
    }
  }

  return (
    <div style={{ padding: 20 }}>
      <div className="header">
        <h1>Todos os clientes</h1>
        <div className="row" style={{width: '35vw'}}>
          <Input.Search
            width={500}
            variant="outlined"
            placeholder="Buscar cliente"
            onSearch={(value) => setQuery(value)}
            loading={isFetching}
            allowClear
            enterButton="Buscar"
          />
          <Button className="footer-btn" icon={
            mode === "grid" ?
              <ViewListOutlined className="view-icon" /> :
              <GridViewOutlined className="view-icon" />
          } onClick={() => setMode(mode === "grid" ? "list" : "grid")} >
            Visualização em {mode === "list" ? "grade" : "lista"}
          </Button>
        </div>
      </div>
      {isError ? (
        <div>Erro ao buscar produtos.</div>
      ) : notFound ? (
        <Result
          status="404"
          title="Nenhum cliente encontrado"
          subTitle="Desculpe, não encontramos nenhum cliente."
          extra={<Button onClick={() => handleEditCustomer(null, true)} type="primary">Cadastrar novo cliente</Button>}
        />
      ) : (
        <List
          pagination={{
            position: "bottom",
            align: "center",
            showTotal: (total) => `Total: ${total} registros`,
            total: customersData.total_entries,
            onChange: (newPage) => setPage(newPage),
          }}
          grid={mode === "grid" ? { gutter: 16, column: 4 } : undefined}
          scroll={{
            y: 400,
          }}
          style={{ fontSize: 14 }}
          dataSource={customersData.data}
          renderItem={(item) => (
            <List.Item >
              <Card
                className={`${mode} render-item`}
                cover={
                  <img
                    className="product-image"
                    style={{ objectFit: "cover" }}
                    alt={item.name}
                    src={item.image_path ? "https://" + item.image_path : NoImage}
                  />
                }

                actions={[
                  <Button
                    className="footer-btn"
                    icon={<EditOutlined />}
                    onClick={() => handleEditCustomer(item)}
                  >
                    Editar
                  </Button>,
                  <Button
                    type="primary"
                    className="footer-btn"
                    icon={<AccountBalanceWallet />}
                    onClick={() => getReceivable(item)}
                  >
                    Notas
                  </Button>,
                ]}
              >
                <Card.Meta
                  description={
                    <div style={{ minHeight: 130 }}>
                      Nome: <b>{item.name}</b>
                      <br /> CPF: <b>{item.document}</b>
                      <br /> Telefone: <b>{item.phone}</b>
                      <br /> Endereço:{" "}
                      <b>{item.street}, Nº {item.number}, {item.neighborhood}</b>
                    </div>
                  }
                  title={item.name}
                  avatar={<Avatar gap={4}>{item.name[0]}</Avatar>}
                />
              </Card>
            </List.Item>
          )}
          loading={isLoading || isFetching}
        />
      )}
      <Details {...receivableOpt} onClose={handleCloseReceivables} />
      <EditModal customer={selectedCustomer} visible={isEditModalVisible} onClose={(shouldRefetch) => {handleEditCustomer(false, shouldRefetch); setIsEditModalVisible(false)}} />
    </div>
  );
};
export default Customers;
