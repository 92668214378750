import NotFound from 'pages/notFound';
import { Route, Routes } from 'react-router-dom';
import PointOfSales from './pos';
const PosRoutes = () => {
    return (
        <Routes>
            <Route index element={<PointOfSales />} />
            {/* <Route path="customer" element={<Customers />} /> */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}
export default PosRoutes;