import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import {
    Avatar,
    Button,
    Card,
    Divider,
    InputNumber,
    Radio,
    Spin,
    Typography
} from "antd";
import api from "api";
import { reverseQuantity, setNativeValue } from "helpers/decimalHelper";
import toBRL from "helpers/toBRL";
import toCurrencyInput from "helpers/toCurrencyInput";
import { useState } from "react";
import { useMutation } from "react-query";
import CustomerSelectorModal from "../../customers/customerSelectorModal";
import styles from "../styles.module.css";

const Checkout = ({ addedProducts, onFinish, onError }) => {

    const [discount, setDiscount] = useState(0);
    const [addition, setAddition] = useState(0);
    const [customerSelectorModalOpen, setCustomerSelectorModalOpen] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState("in_cash");
    const [installments, setInstallments] = useState(1);

    const subTotal = addedProducts.reduce((acc, product) =>
        acc + (parseFloat(product.sale_price) * (reverseQuantity(product.product_quantity, product.unit_of_measurement)))
        , 0);

    const finishSell = async () => {
        const params = {
            company_id: parseInt(sessionStorage.getItem('companyId')),
            discount: `${discount}`,
            addition: `${addition}`,
            payment_method: paymentMethod,
            installments: installments,
            items: addedProducts.map(product => ({ product_id: product.id, quantity: reverseQuantity(product.product_quantity, product.unit_of_measurement) }))
        }
        const { data: { cart_uid: cartId } } = await api.post('/sales/new-cart', params);
        const { data } = await api.post('/sales/new-sale', { ...params, cart_uid: cartId, customer_id: customer?.id });
        return data;
    }
    const newsaleMutation = useMutation({
        mutationFn: () => finishSell(),
        onSuccess: ({ total_amount }) => {
            resetValues();
            onFinish(total_amount)
        },
        onError: () => {
            onError();
        }
    })

    const resetValues = () => {
        setDiscount(0);
        setAddition(0);
        setNativeValue("discountInput", null);
        setNativeValue("additionInput", null);
        setCustomer(null);
        setPaymentMethod("in_cash");
    }
    return (
        <>
            <Card styles={
                {
                    body: {
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        flexDirection: "column",
                        marginBlockEnd: ['on_term', 'credit_card'].includes(paymentMethod) ? "6rem" : 0,
                    }
                }
            } className={styles.checkout}>
                <div className={styles.addClient}>
                    <Button
                        disabled={subTotal === 0}
                        onClick={() => setCustomerSelectorModalOpen(true)}
                        size="large"
                        icon={<Avatar icon={<UserOutlined />} src={customer?.image_path && "https://" + customer.image_path} />}
                        type="link">
                        {customer?.name || "Associar a um cliente"}
                    </Button>
                    {customer && <Button title="Remover cliente" onClick={() => setCustomer(null)} danger size="small" type="dashed" icon={<CloseOutlined />} />}
                </div>
                <Divider>Acréscimos ou descontos</Divider>
                <div className="row">
                    <InputNumber size="large" placeholder="Desconto"
                        disabled={subTotal === 0}
                        variant="filled"
                        prefix="R$"
                        id="discountInput"
                        controls={false}
                        formatter={(value, { input }) => toCurrencyInput(input, value)}
                        onChange={(value) => setDiscount(value / 100)}
                    />
                    <InputNumber size="large" placeholder="Acréscimo"
                        disabled={subTotal === 0}
                        variant="filled"
                        onChange={(value) => setAddition(value / 100)}
                        prefix="R$"
                        id="additionInput"
                        controls={false}
                        formatter={(value, { input }) => toCurrencyInput(input, value)}
                    />
                </div>
                <Divider>Resumo dos valores</Divider>
                <div className={styles.total}>
                    <div className="row">
                        <span className={styles.label}>Subtotal</span>
                        <span className={styles.value}>{toBRL(subTotal)}</span>
                    </div>
                    <div className="row">
                        <span className={styles.label}>Desconto</span>
                        <span className={styles.value}>{toBRL(discount)}</span>
                    </div>
                    <div className="row">
                        <span className={styles.label}>Acréscimos</span>
                        <span className={styles.value}>{toBRL(addition)}</span>
                    </div>
                    {
                        ['on_term', 'credit_card'].includes(paymentMethod) && (
                            <div className="row">
                                <span className={styles.label}>Parcelas</span>
                                <span className={styles.value}>{installments}x de {toBRL((subTotal - discount + addition) / installments)}</span>
                            </div>
                        )
                    }
                    <div className="row" >
                        <span className={styles.label}><Typography.Text style={{ fontSize: 20 }} type="success" strong>Total</Typography.Text></span>
                        <span className={styles.value}>
                            <Typography.Text style={{ fontSize: 20 }} type="success" strong>
                                {toBRL(subTotal - discount + addition)}
                            </Typography.Text></span>
                    </div>
                </div>
                <Divider>Forma de pagamento</Divider>
                <Radio.Group onChange={(v) => setPaymentMethod(v.target.value)} value={paymentMethod} defaultValue="in_cash" buttonStyle="solid" disabled={subTotal === 0}>
                    <Radio.Button value="in_cash">À Vista</Radio.Button>
                    <Radio.Button value="credit_card">Crédito</Radio.Button>
                    <Radio.Button value="debit_card">Débito</Radio.Button>
                    <Radio.Button title={customer === null && "Associe à um cliente para vender à prazo"} disabled={customer === null} value="on_term">À Prazo</Radio.Button>
                </Radio.Group>
                {
                    ['on_term', 'credit_card'].includes(paymentMethod) && (
                        <>
                            <Divider>Parcelas</Divider>
                            <InputNumber
                                disabled={subTotal === 0}
                                size="large"
                                max={12}
                                min={1}
                                defaultValue={1}
                                onChange={(value) => setInstallments(value)}
                                placeholder="Número de parcelas"
                                controls={true}
                                style={{ width: '100%' }}
                            />
                            <Divider />
                        </>
                    ) || <Divider />
                }
                <Button disabled={subTotal === 0} onClick={newsaleMutation.mutate} style={{ justifySelf: 'flex-end' }} size="middle" type="primary" block>Finalizar venda</Button>
            </Card>
            <CustomerSelectorModal onSelect={setCustomer} open={customerSelectorModalOpen} onClose={() => setCustomerSelectorModalOpen(false)} />
            <Spin size="large" fullscreen spinning={newsaleMutation.isLoading} />
        </>
    )
}
export default Checkout;