import {
  AppstoreAddOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  PieChartOutlined,
  ProductFilled,
  SettingFilled,
  UserAddOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import {
  AccountCircle,
  BadgeSharp,
  Key,
  LocationCity,
  LogoutSharp,
  PeopleAlt,
  PlaylistAdd,
  PointOfSale,
  ReceiptLong,
  WorkHistory
} from "@mui/icons-material";
import { Menu } from "antd";
import Icon from "assets/images/Icon.svg";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DarkModeSwitch } from "react-toggle-dark-mode"; // Assuming this is the correct import
import { ThemeContext } from "themes/themeContext";
import NewProduct from "./products/editModal";
import NewCustomer from "./customers/editModal";
import ProfileSettingsModal from "components/modals/settings/profileSettings";

const getItems = (isExpanded, isDarkMode) => [
  {
    label: null,
    key: "0",
    icon: <img alt="icon" height={40} src={Icon} />,
    disabled: true,
    style: {
      cursor: "default",
      marginBottom: 20,
      display: "flex",
      flexDirection: "column",
      paddingInline: 0,
      marginInline: 0,
      alignItems: "center",
      justifyContent: "center",
    },
  },
  {
    label: "Dashboard",
    key: "app",
    icon: <PieChartOutlined />,
  },
  {
    label: "Frente de caixa",
    key: "app/pos",
    icon: <PointOfSale />,
  },
  {
    label: "Produtos",
    key: "app/products",
    icon: <ProductFilled />,
  },
  {
    label: "Clientes",
    key: "app/customers",
    icon: <PeopleAlt />,
  },
  {
    label: "Contas a receber",
    key: "app/receivables",
    icon: <ReceiptLong />
  },
  {
    label: "Vendedores",
    key: "app/employees",
    icon: <BadgeSharp />,
  },
  {
    label: "Histórico de vendas",
    key: "app/sales-history",
    icon: <WorkHistory />,
  },
  {
    label: "Cadastros",
    key: "app/cadastros",
    icon: <PlaylistAdd sx={{ width: 25, height: 25 }} />,
    type: "item",

    children: [
      {
        label: "Novo produto",
        key: "product",
        icon: <AppstoreAddOutlined />,
        type: "item",
      },
      {
        label: "Novo cliente",
        key: "customer",
        icon: <UserAddOutlined />,
        type: "item",
      },
      {
        label: "Novo vendedor",
        key: "employee",
        icon: <UsergroupAddOutlined />,
        type: "item",
      },
    ],
  },
  {
    label: "Configurações",
    key: "settings",
    icon: <SettingFilled />,
    type: "item",
    children: [
      {
        label: "Perfil",
        key: "profile",
        icon: <AccountCircle />,
        type: "item",
      },
      {
        label: "Empresa",
        key: "company",
        icon: <LocationCity />,
        type: "item",
      },
      {
        label: "Certificado",
        key: "digital-certificate",
        icon: <Key />,
      }
    ]
  },
  {
    label: "Usar tema" + (isDarkMode ? " claro" : " escuro"),
    key: "switch-theme",
    icon: <DarkModeSwitch
      sunColor="#edfbf7"
      moonColor="#a127d8"
      checked={!isDarkMode}
      size={20}
      style={{ padding: 0 }}
    />,
    style: {
      position: "absolute",
      bottom: 100,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    }
  },
  {
    label: "Sair da conta",
    key: "logout",
    icon: <LogoutSharp />,
    style: {
      position: "absolute",
      bottom: 60,
      cursor: "pointer",
    }
  },
  {
    label: !isExpanded ? "Expandir" : "Retrair",
    key: "expand",
    icon: !isExpanded ? <DoubleRightOutlined /> : <DoubleLeftOutlined />,
    style: {
      cursor: "pointer",
      position: "absolute",
      bottom: 20,
    },
  },
];

const Layout = ({ children }) => {
  const { toggleTheme, theme } = useContext(ThemeContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [isAddingCustomer, setIsAddingCustomer] = useState(false);
  const [isEdittingProfile, setIsEdittingProfile] = useState(false);
  const { pathname } = useLocation();
  const isDarkMode = theme === "dark";
  const navigate = useNavigate();
  const companyId = sessionStorage.getItem("companyId");

  const handleClick = (key) => {
    switch (key[0]) {
      case "expand":
        setIsExpanded(!isExpanded);
        break;
      case "product":
        setIsAddingProduct(true);
        break;
      case "customer":
        setIsAddingCustomer(true);
        break;
      case "logout":
        sessionStorage.clear();
        navigate("/login");
        break;
      case "switch-theme":
        toggleTheme();
        break;
      case "profile":
        setIsEdittingProfile(true);
        break;
      default:
        navigate(`/${key.reverse().join("/")}`);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          overflow: "hidden",
        }}
      >
        <Menu
          style={{
            minHeight: "100%",
            position: "fixed",
            maxWidth: "18%",
            height: "100%",
            zIndex: 1,
            backgroundColor: isDarkMode ? "#17241f" : "#e3f2ed",
            paddingTop: 20,
            boxShadow: `-2px 2px 7px 1px ${!isDarkMode ? "#17241f" : "#bbe6d8"}`,
          }}
          selectedKeys={[pathname.slice(1)]}
          mode="inline"
          theme={isDarkMode ? "dark" : "light"}
          inlineCollapsed={!isExpanded}
          disabled={[null, "", undefined, 'null'].includes(companyId)}
          items={getItems(isExpanded, isDarkMode, toggleTheme)}
          onClick={({ keyPath }) => handleClick(keyPath)}
        ></Menu>
        <div

          style={{
            width: isExpanded ? "82%" : "100%",
            marginLeft: isExpanded ? "18%" : 80,
            transition: "all 0.1s ease-in-out",
          }}
        >
          <div style={{ marginTop: "4vh" }}>{children}</div>
        </div>
      </div>
      <NewProduct visible={isAddingProduct} onClose={() => setIsAddingProduct(false)} />
      <NewCustomer visible={isAddingCustomer} onClose={() => setIsAddingCustomer(false)} />
      <ProfileSettingsModal visible={isEdittingProfile} onClose={() => setIsEdittingProfile(false)} />
    </>
  );
};

export default Layout;
