import { App, Divider, InputNumber, Modal, Radio } from "antd";
import api from "api";
import toCurrencyInput from "helpers/toCurrencyInput";
import { useState } from "react";
import { useMutation } from "react-query";

const PaymentModal = ({ open, onClose, receivableId }) => {
    const companyId = sessionStorage.getItem("companyId");
    const { message } = App.useApp();
    const [paymentProps, setPaymentProps] = useState(
        {
            paymentMethod: null,
            value: null
        });

    const handleReceive = async (receivableId, value, paymentMethod) => {
        const payload = {
            receivable_id: receivableId,
            amount: value,
            payment_method: paymentMethod,
            company_id: companyId,
        }
        await api.post('/billings/payment', payload);
    }

    const newPaymentMutation = useMutation({
        mutationFn: ({ value, paymentMethod }) => handleReceive(receivableId, value, paymentMethod),
        onSuccess: () => {
            setPaymentProps({ open: false, receivableId: null, paymentMethod: null, value: null });
            message.success("Pagamento registrado com sucesso");
            onClose();
        },
        onError: (error) => {
            message.error("Erro ao registrar pagamento. Tente novamente mais tarde.");
            console.log(error)
        }
    });
    const onChangePaymentProps = (key, value) => setPaymentProps({ ...paymentProps, [key]: value });

    return (
        <Modal
            style={{zIndex: 9999}}
            title="Registrar pagamento"
            open={open}
            onClose={onClose}
            onCancel={onClose}
            onOk={() => newPaymentMutation.mutate(paymentProps)}
            confirmLoading={newPaymentMutation.isLoading}
        >
            <Divider>Valor do pagamento</Divider>
            <InputNumber
                style={{ width: '100%' }}
                variant="filled"
                onChange={(value) => onChangePaymentProps("value", value / 100)}
                prefix="R$"
                controls={false}
                formatter={(value, { input }) => toCurrencyInput(input, value)}
            />
            <Divider>Forma de pagamento</Divider>
            <Radio.Group style={{ width: '100%' }} onChange={(event) => onChangePaymentProps("paymentMethod", event.target.value)} value={paymentProps.paymentMethod} defaultValue="in_cash" buttonStyle="solid">
                <Radio.Button value="in_cash">Em Dinheiro</Radio.Button>
                <Radio.Button value="credit_card">Cartão de crédito</Radio.Button>
                <Radio.Button value="debit_card">Cartão de débito</Radio.Button>
                <Radio.Button value="pix">Pix</Radio.Button>
            </Radio.Group>

        </Modal>
    )
};

export default PaymentModal;