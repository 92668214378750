import api from "../api";
const signup = async (email, password, confirmPassword, name, cpf, age, onError, loading) => {
  if ([email, password, name, cpf].includes("")) {
    return onError();
  }
  loading(true);
  const payload = {
    email: email,
    password: password,
    password_confirmation: confirmPassword,
    name: name,
    cpf: cpf,
    role: "admin",
    age: age
  };
  try {
    const { data } = await api.post("/accounts/sign_up", payload);
    sessionStorage.setItem("apiToken", `Bearer ${data.jwt}`);
    sessionStorage.setItem("companyId", data.user?.company_id);
    loading(false);
    if (!data.user?.company_id) return (window.location.href = "/app/new-company");
    return (window.location.href = "/app");
  } catch (error) {
    if(error.response.status === 422) {
      loading(false);
      return onError(error.response.data?.errors[Object.keys(error.response.data?.errors)[0]][0]);
    }
    sessionStorage.setItem("apiToken", "");
    loading(false);
    return onError("Erro ao cadastrar, tente novamente em breve.");
  }
};

const login = async (email, password, onError, loading) => {
  if ([email, password].includes("")) {
    return onError();
  }
  loading(true);
  const payload = {
    email: email,
    password: password,
  };
  try {
    const { data } = await api.post("/accounts/login", payload);
    sessionStorage.setItem("apiToken", `Bearer ${data.jwt}`);
    sessionStorage.setItem("companyId", data.user?.company_id);
    loading(false);
    if (!data.user?.company_id) return (window.location.href = "/app/new-company");
    if (data.application === "billing") {
      return (window.location.href = "/billing");
    }
    return (window.location.href = "/app");
  } catch (error) {
    sessionStorage.setItem("apiToken", "");
    loading(false);
    return onError();
  }
};
export { login, signup };
