export default function toCurrencyInput(int, fallback = null, opts = {}) {
    let decimals = opts.decimals || 2
    if (!int && !fallback) {
        return
    }

    // transforming the int parameter into string
    let currency = (int !== 'undefined' && int || fallback).toString()

    // checking if the parameter has a number after the regex use
    if (currency.replace(/\D/g, '').length === 0) {
        return
    }
    if (opts.decimals) {
        currency = (parseInt(currency.replace(/\D/g, '')) / (10 ** decimals)).toFixed(decimals);
        return `${currency}`.replace('.', ',')
    }
    // applying cents mask
    if (currency.length < 4) {
        currency = currency.padStart(4, '0')
        currency = currency.replace(/([0-9]{2})$/g, ',$1')
    } else {
        currency = parseInt(currency.replace(/\D/g, '')) / 100
        currency = currency
            .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            .substring(3)
    }
    return currency
}
